<template>
  <div
    class="signin-inner my-3 my-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500"
  >
    <h1 class="h3 mb-4">Reset password</h1>
    <form action="#">
      <!-- Form -->
      <div class="mb-4">
        <label for="email">Your Email</label>
        <div class="input-group">
          <input
            type="email"
            class="form-control"
            placeholder="example@company.com"
            id="email"
            required
            disabled
          />
        </div>
      </div>
      <!-- End of Form -->
      <!-- Form -->
      <div class="mb-4">
        <label for="password">Your Password</label>
        <div class="input-group">
          <span class="input-group-text" id="basic-addon4"
            ><span class="fas fa-unlock-alt"></span
          ></span>
          <input
            type="password"
            placeholder="Password"
            class="form-control"
            id="password"
            required
            autofocus
          />
        </div>
      </div>
      <!-- End of Form -->
      <!-- Form -->
      <div class="mb-4">
        <label for="confirm_password">Confirm Password</label>
        <div class="input-group">
          <span class="input-group-text" id="basic-addon5"
            ><span class="fas fa-unlock-alt"></span
          ></span>
          <input
            type="password"
            placeholder="Confirm Password"
            class="form-control"
            id="confirm_password"
            required
          />
        </div>
      </div>
      <!-- End of Form -->
      <button type="submit" class="btn btn-block btn-primary">
        Reset password
      </button>
    </form>
    <div class="d-flex justify-content-center align-items-center mt-4">
      <span class="font-weight-normal">
        Go back to the
        <router-link to="login" class="font-weight-bold">
          login page
        </router-link>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ResetPassword"
};
</script>

<style scoped></style>
